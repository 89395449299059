import {samples} from "@/http-common";

class SamplesDataService {
    async getAll() {
        return await samples.get("/list");
    }

    async searchList(page, step, searchQuery) {
        return await samples.get("/search?q="+searchQuery, {params: {page, step}});
    }

    async getPaginated(page, step) {
        return await samples.get("/list", {params: {page, step}});
    }

    async getOne(sha256) {
        return await samples.get(`/sample/${sha256}`);
    }

    async search(q) {
        return await samples.get('/search/', {params: {q}})
    }

    async paginated_search(q, page, step) {
        return await samples.get('/search/', {params: {q, page, step}})
    }

    async findBySha256(sha256) {
        return await samples.get(`/sample?sha256=${sha256}`);
    }

    async uploadFile(data) {
        return await samples.post(`/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}

export default new SamplesDataService();
