<template>
  <ul class="nav navbar-nav navbar-right">
    <li class="dropdown">
      <a href="#" @click="logOutOpen" id="open-click" class="dropdown-toggle text-white" data-toggle="dropdown"><b id="open-click-b">Logout</b> <span class="caret"></span></a>
      <ul id="logout-dp" class="dropdown-menu"  v-if="isActiveLogOut">
        <li>
          <div class="row">
            <div class="col-md-12" id="dropdown_menu">
<!--              <div></div><br>-->
              <strong>Api key:</strong>
              <div @click="copy(api_key)" class="tooltiptext">{{api_key}}</div>
              <div class="social-buttons">
                <a href="#" @click="logOut"  class="btn btn-primary">Logout</a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import {EventBus} from '@/EventBus';

export default {
  name: "LogOut",
  props:['api_key'],
  data() {
    return {
      isActiveLogOut: false
    }
  },
  methods: {
    logOutOpen(){
      this.isActiveLogOut = !this.isActiveLogOut;
      let showHide = this.isActiveLogOut ? 'none' : 'block';
      if(document.getElementById("view-json-btn")){
        document.getElementById("view-json-btn").style.display = showHide;
      }
    },

    logOut(){
      sessionStorage.removeItem("user_api_key")
      EventBus.$emit("user_api_key", null);
    },

    async copy(s) {
      await navigator.clipboard.writeText(s);
      this.$bvToast.toast('Copied: ' +s, {
        autoHideDelay: 5000,
        noCloseButton: true,
        solid: true,
        variant: "success",
      });
    }
  },
  mounted() {
    let self = this;
    document.addEventListener('click', function(e){
      if(e.target.id !=="dropdown_menu" && e.target.id !== "open-click-b" && e.target.id !== "open-click"){
        self.isActiveLogOut = false

      }
    });
  },

  created() {
    this.canCopy = !!navigator.clipboard;
  }
}
</script>

<style scoped>
.dropdown-toggle{
  position: relative;
}

.dropdown{
  position: relative;
}

#logout-dp{
  position: absolute;
  min-width: 250px;
  padding: 14px 14px 0;
  overflow:hidden;
  display: block;
  z-index: 9999999;
}

.dropdown-menu{
  right: 0 !important;
  left: unset !important;
}

#logout-dp .help-block{
  font-size:12px
}
#logout-dp .bottom{
  background-color:rgba(255,255,255,.8);
  border-top:1px solid #ddd;
  clear:both;
  padding:14px;
}
#logout-dp .social-buttons{
  margin:12px 0
}
#logout-dp .social-buttons a{
  width: 100%;
}
#logout-dp .form-group {
  margin-bottom: 10px;
}
.btn-fb{
  color: #fff;
  background-color: #dc4c39;
}
.btn-fb:hover{
  color: #fff;
  background-color:#62332d;
}
.btn-tw{
  color: #fff;
  background-color: #444444;
}
.btn-tw:hover{
  color: #fff;
  background-color:#9b8b8b;
}
@media(max-width:768px){
  #logout-dp{
    background-color: inherit;
    color: #fff;
  }
  #logout-dp .bottom{
    background-color: inherit;
    border-top:0 none;
  }
}

.opendropdown {
  display: block;
}

.tooltiptext{
  cursor: pointer;
}

</style>