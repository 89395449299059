<template>
  <div>
    <b-sidebar id="sidebar-privacy" no-header right shadow backdrop backdrop-variant="light">
      <template #default="{ hide }">
      <div>
        <header class="d-flex p-3 position-fixed w-100 sticky-top bg-light" >
          <h3 class="flex-fill text-primary font-weight-bold">Privacy Policy</h3>
          <b-icon icon="x" font-scale="2" @click="hide"></b-icon>
        </header>
        <div class="pt-4 pb-4 pr-4 pl-4 mt-5">

          This privacy policy applies between you, the User of this Website and analyse.rs, the owner
          and provider of this Website.
          analyse.rs takes the privacy of your information very seriously.
          This privacy policy applies to our use of any and all Data collected by us or provided by you in
          relation to your use of the Website.
          <br><br><strong class="text-danger">*** Please read this privacy policy carefully
          ***</strong><br><br>
          <h6 data-v-665abb26="" class="marker">Definitions and interpretation</h6>
          <div data-v-665abb26="" class="pl-3">
            In this privacy policy, the following definitions are used
            <div data-v-665abb26="" class="mb-3">
              <dl data-v-665abb26="" class="even">
                <dt data-v-665abb26=""><b data-v-665abb26="">Data</b></dt>
                <dd data-v-665abb26="">collectively all information that you submit to analyse.rs via
                  the Website. This definition incorporates, where applicable, the definitions
                  provided in the Data Protection Laws;
                </dd>
              </dl>
              <dl data-v-665abb26="" class="odd">
                <dt data-v-665abb26=""><b data-v-665abb26="">Data Protection Laws</b></dt>
                <dd data-v-665abb26="">any applicable law relating to the processing of personal Data,
                  including but not limited to the Directive 96/46/EC (Data Protection Directive) or
                  the GDPR, and any national implementing laws, regulations and secondary legislation,
                  for as long as the GDPR is effective in the UK;
                </dd>
              </dl>
              <dl data-v-665abb26="" class="even">
                <dt data-v-665abb26=""><b data-v-665abb26="">GDPR</b></dt>
                <dd data-v-665abb26="">the General Data Protection Regulation (EU) 2016/679;</dd>
              </dl>
              <dl data-v-665abb26="" class="odd">
                <dt data-v-665abb26=""><b data-v-665abb26="">analyze<br>.rs, we</b></dt>
                <dd data-v-665abb26="">analyse.rs, the company/website that has made this tool so
                  users
                  can analyze suspicious emails;
                </dd>
              </dl>
              <dl data-v-665abb26="" class="even">
                <dt data-v-665abb26=""><b data-v-665abb26="">User or you</b></dt>
                <dd data-v-665abb26="">any third party that accesses the Website and is not either (i)
                  employed by sandbox.org and acting in the course of their employment or (ii) engaged
                  as a consultant or otherwise providing services to analyse.rs and accessing
                  the Website in connection with the provision of such services; and
                </dd>
              </dl>
              <dl data-v-665abb26="" class="odd">
                <dt data-v-665abb26=""><b data-v-665abb26="">Website</b></dt>
                <dd data-v-665abb26="">the website that you are currently using, <a data-v-665abb26=""
                                                                                    href="https://www.sandbox.org"
                                                                                    target="_blank">https://www.analyse.rs</a>,
                  and any sub-domains of this site unless expressly excluded by their own terms and
                  conditions.
                </dd>
              </dl>
            </div>
            <p data-v-665abb26="">In this privacy policy, unless the context requires a different
              interpretation:</p>
            <ol data-v-665abb26="">
              <li data-v-665abb26="">the singular includes the plural and vice versa;</li>
              <li data-v-665abb26="">references to sub-clauses, clauses, schedules or appendices are to
                sub-clauses, clauses, schedules or appendices of this privacy policy;
              </li>
              <li data-v-665abb26="">a reference to a person includes firms, companies, government entities,
                trusts and partnerships;
              </li>
              <li data-v-665abb26="">"including" is understood to mean "including without limitation";</li>
              <li data-v-665abb26="">reference to any statutory provision includes any modification or
                amendment of it;
              </li>
              <li data-v-665abb26="">the headings and sub-headings do not form part of this privacy policy.
              </li>
            </ol>
          </div>
          <h6 data-v-665abb26="" class="marker">Scope of this privacy policy</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">This privacy policy applies only to the
            actions of analyse.rs and Users with respect to this Website. It does not extend to any websites
            that can be accessed from this Website including, but not limited to, any links we may provide to
            social media websites.</p>
            <p data-v-665abb26="">For purposes of the applicable Data Protection Laws, analyse.rs is the "data
              controller". This means that analyse.rs determines the purposes for which, and the manner in
              which, your Data is processed.</p></div>
          <h6 data-v-665abb26="" class="marker">Data collected</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">We may collect the following Data, which
            includes personal Data, from you:</p>
            <ol data-v-665abb26="">
              <li data-v-665abb26="">name;</li>
              <li data-v-665abb26="">contact Information such as email addresses and telephone numbers;</li>
              <li data-v-665abb26="">IP address (automatically collected);</li>
              <li data-v-665abb26="">web browser type and version (automatically collected);</li>
              <p data-v-665abb26="">in each case, in accordance with this privacy policy.</p></ol>
          </div>
          <h6 data-v-665abb26="" class="marker">How we collect Data</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">We collect Data in the following ways:</p>
            <ol data-v-665abb26="">
              <li data-v-665abb26="">data is given to us by you; and</li>
              <li data-v-665abb26="">data is collected automatically.</li>
            </ol>
          </div>
          <h6 data-v-665abb26="" class="marker">Data that is given to us by you</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">analyse.rs will collect your Data in a
            number of ways, for example:</p>
            <ol data-v-665abb26="">
              <li data-v-665abb26="">when you contact us through the Website, by telephone, post, e-mail or
                through any other means;
              </li>
              <li data-v-665abb26="">when you register with us and set up an account to receive our
                products/services;
              </li>
              <li data-v-665abb26="">when you use our services;</li>
              <p data-v-665abb26="">in each case, in accordance with this privacy policy.</p></ol>
          </div>
          <h6 data-v-665abb26="" class="marker">Data that is collected automatically</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">To the extent that you access the Website, we
            will collect your Data automatically, for example:</p>
            <ol data-v-665abb26="">
              <li data-v-665abb26="">we automatically collect some information about your visit to the
                Website. This information helps us to make improvements to Website content and navigation,
                and includes your IP address, the date, times and frequency with which you access the
                Website and the way you use and interact with its content.
              </li>
            </ol>
          </div>
          <h6 data-v-665abb26="" class="marker">Our use of Data</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">Any or all of the above Data may be required
            by us from time to time in order to provide you with the best possible service and experience when
            using our Website. Specifically, Data may be used by us for the following reasons:</p>
            <ol data-v-665abb26="">
              <li data-v-665abb26="">improvement of our products / services;</li>
              <p data-v-665abb26="">in each case, in accordance with this privacy policy.</p></ol>
            <p data-v-665abb26="">We may use your Data for the above purposes if we deem it necessary to do so
              for our legitimate interests. If you are not satisfied with this, you have the right to object
              in certain circumstances (see the section headed "Your rights" below).</p>
            <p data-v-665abb26="">When you register with us and set up an account to receive our services, the
              legal basis for this processing is the performance of a contract between you and us and/or
              taking steps, at your request, to enter into such a contract.</p></div>
          <h6 data-v-665abb26="" class="marker">Keeping Data secure</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">We will use technical and organisational
            measures to safeguard your Data, for example:</p>
            <ol data-v-665abb26="">
              <li data-v-665abb26="">access to your account is controlled by a password and a user name that
                is unique to you and known only to you.
              </li>
              <li data-v-665abb26="">we store your Data on secure servers.</li>
            </ol>
            <p data-v-665abb26="">Technical and organisational measures include measures to deal with any
              suspected data breach. If you suspect any misuse or loss or unauthorised access to your Data,
              please let us know immediately by contacting us via this e-mail address: <a data-v-665abb26=""
                                                                                          href="mailto:abuse@analyse.rs">abuse@analyse.rs</a>.
            </p>
            <p data-v-665abb26="">If you want detailed information from Get Safe Online on how to protect your
              information and your computers and devices against fraud, identity theft, viruses and many other
              online problems, please visit <a data-v-665abb26="" href="https://www.getsafeonline.org" target="_blank">www.getsafeonline.org</a>.
              Get Safe Online is
              supported by HM Government and leading businesses.</p></div>
          <h6 data-v-665abb26="" class="marker">Data retention</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">Unless a longer retention period is required
            or permitted by law, we will only hold your Data on our systems for the period necessary to fulfil
            the purposes outlined in this privacy policy or until you request that the Data be deleted.</p>
            <p data-v-665abb26="">Even if we delete your Data, it may persist on backup or archival media for
              legal, tax or regulatory purposes.</p></div>
          <h6 data-v-665abb26="" class="marker">Your rights</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">You have the following rights in relation to
            your Data:</p>
            <ol data-v-665abb26="">
              <li data-v-665abb26=""><b data-v-665abb26="">Right to access</b> - he right to request (i)
                copies of the information we hold about you at any time, or (ii) that we modify, update or
                delete such information. If we provide you with access to the information we hold about you,
                we will not charge you for this, unless your request is "manifestly unfounded or excessive."
                Where we are legally permitted to do so, we may refuse your request. If we refuse your
                request, we will tell you the reasons why.
              </li>
              <li data-v-665abb26=""><b data-v-665abb26="">Right to correct</b> - the right to have your Data
                rectified if it is inaccurate or incomplete.
              </li>
              <li data-v-665abb26=""><b data-v-665abb26="">Right to erase</b> - the right to request that we
                delete or remove your Data from our systems.
              </li>
              <li data-v-665abb26=""><b data-v-665abb26="">Right to restrict our use of your Data</b> - the
                right to "block" us from using your Data or limit the way in which we can use it.
              </li>
              <li data-v-665abb26=""><b data-v-665abb26="">Right to data portability</b> - the right to
                request that we move, copy or transfer your Data.
              </li>
              <li data-v-665abb26=""><b data-v-665abb26="">Right to object</b> - the right to object to our
                use of your Data including where we use it for our legitimate interests.
              </li>
            </ol>
            <p data-v-665abb26="">To make enquiries, exercise any of your rights set out above, or withdraw your
              consent to the processing of your Data (where consent is our legal basis for processing your
              Data), please contact us via this e-mail address: <a data-v-665abb26=""
                                                                   href="mailto:abuse@analyse.rs">abuse@analyse.rs</a>
            </p>
            <p data-v-665abb26="">If you are not satisfied with the way a complaint you make in relation to your
              Data is handled by us, you may be able to refer your complaint to the relevant data protection
              authority. For the UK, this is the Information Commissioner's Office (ICO). The ICO's contact
              details can be found on their website at <a data-v-665abb26="" href="https://ico.org.uk/" target="_blank">https://ico.org.uk/</a>.
            </p>
            <p data-v-665abb26="">It is important that the Data we hold about you is accurate and current.
              Please keep us informed if your Data changes during the period for which we hold it.</p></div>
          <h6 data-v-665abb26="" class="marker">Transfers outside the European Economic Area</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">Data which we collect from you may be stored
            and processed in and transferred to countries outside of the European Economic Area (EEA). For
            example, this could occur if our servers are located in a country outside the EEA or one of our
            service providers is situated in a country outside the EEA. We also share information with our group
            companies, some of which are located outside the EEA.</p>
            <p data-v-665abb26="">We will only transfer Data outside the EEA where it is compliant with data
              protection legislation and the means of transfer provides adequate safeguards in relation to
              your data, eg by way of data transfer agreement, incorporating the current standard contractual
              clauses adopted by the European Commission, or by signing up to the EU-US Privacy Shield
              Framework, in the event that the organisation in receipt of the Data is based in the United
              States of America.</p>
            <p data-v-665abb26="">To ensure that your Data receives an adequate level of protection, we have put
              in place appropriate safeguards and procedures with the third parties we share your Data with.
              This ensures your Data is treated by those third parties in a way that is consistent with the
              Data Protection Laws.</p></div>
          <h6 data-v-665abb26="" class="marker">Links to other websites</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">This Website may, from time to time, provide
            links to other websites. We have no control over such websites and are not responsible for the
            content of these websites. This privacy policy does not extend to your use of such websites. You are
            advised to read the privacy policy or statement of other websites prior to using them.</p></div>
          <h6 data-v-665abb26="" class="marker">Changes of business ownership and control</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">analyse.rs may, from time to time, expand or
            reduce our business and this may involve the sale and/or the transfer of control of all or part of
            analyse.rs. Data provided by Users will, where it is relevant to any part of our business so
            transferred, be transferred along with that part and the new owner or newly controlling party will,
            under the terms of this privacy policy, be permitted to use the Data for the purposes for which it
            was originally supplied to us.</p>
            <p data-v-665abb26="">We may also disclose Data to a prospective purchaser of our business or any
              part of it.</p>
            <p data-v-665abb26="">In the above instances, we will take steps with the aim of ensuring your
              privacy is protected.</p></div>
          <h6 data-v-665abb26="" class="marker">General</h6>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">You may not transfer any of your rights under
            this privacy policy to any other person. We may transfer our rights under this privacy policy where
            we reasonably believe your rights will not be affected.</p>
            <p data-v-665abb26="">If any court or competent authority finds that any provision of this privacy
              policy (or part of any provision) is invalid, illegal or unenforceable, that provision or
              part-provision will, to the extent required, be deemed to be deleted, and the validity and
              enforceability of the other provisions of this privacy policy will not be affected.</p>
            <p data-v-665abb26="">Unless otherwise agreed, no delay, act or omission by a party in exercising
              any right or remedy will be deemed a waiver of that, or any other, right or remedy.</p></div>
          <h4 data-v-665abb26="" class="mt-4 text-primary">Changes to this privacy policy</h4>
          <div data-v-665abb26="" class="pl-5"><p data-v-665abb26="">analyse.rs reserves the right to change this
            privacy policy as we may deem necessary from time to time or as may be required by law. Any changes
            will be immediately posted on the Website and you are deemed to have accepted the terms of the
            privacy policy from your first use of the Website following the alterations.</p>
            <p data-v-665abb26="">You may contact analyse.rs by email at <a data-v-665abb26=""
                                                                                  href="mailto:abuse@analyse.rs">abuse@analyse.rs</a>
            </p></div>
        </div>
        <br/>&nbsp;
      </div>
      </template>
      <template #footer="{ hide }">
        <div class="px-3 py-2 flex-fill">
          <b-button size="sm" @click="hide">Got it</b-button>
        </div>
      </template>
    </b-sidebar>
    <b-sidebar id="sidebar-eula" no-header right shadow backdrop backdrop-variant="light">
      <template #default="{ hide }">
      <div>
        <header class="d-flex p-3 position-fixed w-100 sticky-top bg-light" >
          <h3 class="flex-fill text-primary font-weight-bold">End User's License Agreement</h3>
          <b-icon icon="x" font-scale="2" @click="hide"></b-icon>
        </header>
        <div class="pt-4 pb-4 pr-4 pl-4 mt-5">
          <strong class="text-danger">Last updated: 2022-01-30</strong><br><br>
          <p>This End-User License Agreement (referred to as the "EULA") is a legally binding agreement between you, the Licensee, an individual customer or entity, and the Analyze.rs, the company, and the author of Analyze.rs, the Software, which may include associated media, printed materials, and online or electronic documentation. This Agreement is a legally binding contract that includes terms that limit your legal rights and Licensors' liability to you, and shall govern all access to and use of this Software. You hereby agree, without limitation or alteration, to all the terms and conditions contained herein.
          </p><p>
          By installing, copying, or otherwise using the Licensed Product (Software), the Licensee agrees to be bound by the terms and conditions outlined in this EULA. However, if the Licensee does not agree to the terms and conditions outlined in this EULA, the said Licensee may not download, install, or use Software.
        </p>
          <h6 data-v-665abb26="" class="marker"><strong>Definitions</strong></h6>
          <div data-v-665abb26="" class="pl-3">
            <p>"EULA" shall refer to this End-User-License-Agreement, including any amendment to this Agreement.</p>
            <p>"Licensee" shall refer to the individual or entity that downloads and uses the Software.</p>
            <p>"Licensor" shall refer to the company or author, Analyze.rs, located at India.</p>
            <p>"Software/Licensed product" shall mean Analyze.rs, the Licensed Product provided pursuant to this EULA.</p>
          </div>
          <h6 data-v-665abb26="" class="marker"><strong>Grant of License</strong></h6>
          <div data-v-665abb26="" class="pl-3"><p>Subject to the terms of this EULA, the Analyze.rs hereby grants to the Licensee, a royalty-free, revocable, limited, non-exclusive license during the term of this EULA to possess and to use a copy of the Software. The Software is being distributed by Analyze.rs. Licensee is not allowed to make a charge for distributing this Software, either for profit or merely to recover media and distribution costs.
          </p>
            <p data-v-665abb26="">For purposes of the applicable Data Protection Laws, analyse.rs is the "data
              controller". This means that analyse.rs determines the purposes for which, and the manner in
              which, your Data is processed.</p></div>
          <h6 data-v-665abb26="" class="marker"><strong>Intellectual Property</strong></h6>
          <div data-v-665abb26="" class="pl-3"><p>
            You hereby unconditionally agree that all right, title and interest in the copyrights and other intellectual property rights in the Licensed Product reside with the Licensors. The trademarks, logos, designs, and service marks appearing on the Licensed Product are registered and unregistered marks of Licensor. Accordingly, nothing in this EULA or the Licensed Product grants you any right to use any form of intellectual property contained in the Licensed Product.
          </p><p>
            Therefore, all rights, titles, interests, and copyrights in and/or to the Software, including but not limited to all images, graphics, animations, audio, video, music, text, data, code, algorithm, and information, are owned by the Licensor. Accordingly, the Software is protected by all applicable copyright laws and international treaties, and the Licensee is expected to use the Software concerning all intellectual property contained therein, except as otherwise provided for in this EULA.
          </p>
          </div>
          <h6 data-v-665abb26="" class="marker"><strong>Description of Rights and Limitations</strong></h6>
          <div data-v-665abb26="" class="pl-3"><p><strong>Installation and Use:</strong><br/>
              Licensee may install and use the Software on a shared computer or concurrently on different computers, and make multiple back-up copies of the Software, solely for Licensee's use within Licensee's business or personal use.
              <br/><strong>Reproduction and Distribution:</strong>
            <br/>Licensee may not duplicate or re-distribute copies of the Software, without the Licensors express written permission.
            <br/><strong>Licensee Limitation: </strong>The Licensee may not:
            <ol>
            <li>Use the Licensed Product for any purpose other than personal and non-commercial purposes;</li>
            <li>Use the Licensed Product for any illegal or unlawful purpose;</li>
            <li>Gather factual content or any other portion of the Licensed product by any automated means, including but not limited to database scraping or screen scraping; or</li>
            <li>Reverse engineer, decompile, or disassemble Software, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding the limitation.</li>
          </ol>
          </p>
          </div>
          <h6 data-v-665abb26="" class="marker"><strong>Update and Maintenance</strong></h6>
          <div data-v-665abb26="" class="pl-3"><p>Analyze.rs shall provide updates and maintenance on a as-needed basis.</p>
          </div>
          <h6 data-v-665abb26="" class="marker"><strong>Support</strong></h6>
          <div data-v-665abb26="" class="pl-5"><p>Analyze.rs has no obligation to Software support, or to continue providing or updating any of the Software.</p>
          </div>
          <h6 data-v-665abb26="" class="marker"><strong>General Provisions</strong></h6>
          <div data-v-665abb26="" class="pl-3"><p><strong>Termination</strong><br/>
            In the event of termination, all licenses provided under this EULA shall immediately terminate, and you agree to discontinue accessing or attempting to access this Licensed product.</p>
            <p>Accordingly, this EULA may be:</p>
            <ol data-v-665abb26="">
              <li data-v-665abb26="">Automatically terminated if the Licensee fails to comply with any of the terms and conditions under this EULA;</li>
              <li data-v-665abb26="">Terminated by Analyze.rs; or</li>
              <li data-v-665abb26="">Terminated by the Licensee.</li>
            </ol>
            <p data-v-665abb26="">Either Analyze.rs or the Licensee may terminate this EULA immediately upon written notice to the other party, including but not limited to electronic mail.</p>
            <p><strong>Non-Transferability</strong><br/>
              The Licensee has the option to permanently transfer all rights under this Agreement, provided the recipient agrees to the terms of this EULA. Accordingly, this EULA is not assignable or transferable by the Licensee without the prior written consent of Analyze.rs; and any attempt to do so shall be void.
            </p>
            <p><strong>Notice</strong><br/>
              Any notice, report, approval or consent required under this EULA shall be in writing and deemed to have been duly given if delivered by recorded delivery to the respective addresses of the parties.
            </p>
            <p><strong>Integration</strong><br/>
              Both parties hereby agree that this EULA is the entire and exclusive statement and legal acknowledgement of the mutual understanding of the parties and supersedes and cancels any previous written and oral agreement and/or communication relating to the subject matter of this EULA.
            </p>
            <p><strong>Severability</strong><br/>
              No delay or failure to exercise, on the part of either party, any privilege, power or rights under this EULA shall operate as a waiver of any of the terms and provisions of this EULA. Accordingly, no single or partial exercise of any right under this Agreement shall preclude further exercise of any other right under this EULA. Suppose any of the outlined provisions of this EULA is deemed to be unenforceable or invalid in whole or in part by a court of competent jurisdiction. In that case, such provision shall be limited to the minimum extent necessary for this EULA to remain in full force and effect and enforceable. The remaining provisions of this Agreement shall not be rendered unenforceable or invalid. They shall continue to be enforceable and valid in isolation of the unenforceable and invalid provisions of this EULA.
            </p>
            <p><strong>Warranty and Disclaimer</strong><br/>
              Analyze.rs, and author of this Software, expressly disclaim any warranty for the Analyze.rs. The Licensed Product and all applicable documentation is provided as-is, without warranty of any kind, whether express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. Accordingly, the Licensee accepts any risk arising out of the use or performance of the Software.
            </p>
            <p><strong>Limited Liability</strong><br/>
              The Licensee agrees that the Analyze.rs shall not be liable to Licensee, or any other related person or entity claiming any loss of profits, income, savings, or any other consequential, incidental, special, punitive, direct or indirect damage, whether arising in contract, tort, warranty, or otherwise. Even if Analyze.rs has been advised of the possibility of such damages. These limitations shall necessarily apply regardless of the primary purpose of any limited remedy. Under no circumstances shall Analyze.rs aggregate liability to the Licensee, or any other person or entity claiming through the Licensee, exceed the actual monetary amount paid by the Licensee to Analyze.rs for the Software.
            </p>
            <p><strong>Indemnification</strong><br/>
              You hereby agree to indemnify and hold Analyze.rs harmless from and against all liabilities, damages, losses or expenses, including but not limited to reasonable attorney or other professional fees in any claim, demand, action or proceeding initiated by any third-party against Analyze.rs, arising from any of your acts, including without limitation, violating this EULA or any other agreement or any applicable law.
            </p>
            <p><strong>Entire Agreement</strong><br/>
              This Agreement rightly constitutes the entire understanding between the Analyze.rs and the Licensee and all parties involved. It supersedes all prior agreements of the parties, whether written or oral, express or implied, statement, condition, or a representation or warranty.
            </p>
            <p><strong>Governing Law and Jurisdiction</strong><br/>
              This EULA shall be deemed to be construed under the jurisdiction of the courts located in India, without regard to conflicts of laws as regards the provisions thereof. Any legal action relating to this EULA shall be brought exclusively in the courts of India, and all parties consent to the jurisdiction thereof. Furthermore, the prevailing party in any action to enforce this EULA shall be entitled to recover costs and expenses including, without limitation, legal fees. Accordingly, this EULA is made within the exclusive jurisdiction of the India, and its jurisdiction shall supersede any other jurisdiction of either party's election.
            </p>
          </div>
          <br/>&nbsp;
        </div>
      </div>
      </template>
      <template #footer="{ hide }">
        <div class="px-3 py-2 flex-fill">
          <b-button size="sm" @click="hide">Got it</b-button>
        </div>
      </template>
    </b-sidebar>
    <footer>&copy; Analyze.rs | <a href="javascript:void(0)" v-b-toggle.sidebar-privacy>&nbsp;Privacy Policy&nbsp;</a> | <a href="javascript:void(0)" v-b-toggle.sidebar-eula>&nbsp;EULA&nbsp;</a> | contact: hi at analyze.rs</footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',

}
</script>
<style>
#sidebar-eula, #sidebar-privacy {
  width: 80vw;
}
</style>
