<template>
  <div>
    <div class="card-title d-flex align-items-center">
      <h5 class="flex-fill flex-grow-1">
        <strong v-if="searchQuery">Search results: {{ pagination.total }} found!</strong>
        <strong v-else>Recent submissions</strong>
      </h5>
      <b-form-select v-model="pagination.itemsPerPage" :options="itemsPerPageOptions" class="w-25"></b-form-select>
      <br/>
    </div>
    <div>
      <div class="skeleton-table">
        <b-skeleton-table
          v-if="isLoading === true"
          :rows="10"
          :columns="4"
          :table-props="{ bordered: true, striped: true }"

      ></b-skeleton-table>
      </div>
      <b-table hover :items="items" :fields="fields" id="tableData" v-if="isLoading === false">
        <template #cell(Sha256)="data">
          <span class="line" :class="(data.item.AI_Verdict || '').toLowerCase()"></span>
          <router-link :to="'/detail/' + data.value">{{
              data.value
            }}
          </router-link>
          <div class="ml-2">
            <b-badge class="mr-1" variant="secondary" v-for="(item, i) in data.item.tags" :key="'tags' + i">
              {{ item }}
            </b-badge>
            <b-badge class="mr-1" variant="primary" v-if="data.item.moreTags > 0">
              + {{ data.item.moreTags }} more
            </b-badge>
          </div>
        </template>
        <template>
          <b-icon-arrow-down></b-icon-arrow-down>
        </template>
      </b-table>
      <b-pagination v-if="items.length"
          v-model="pagination.currentPage"
          :total-rows="pagination.total"
          :per-page="pagination.itemsPerPage"
      ></b-pagination>
    </div>
    <div class="not-found-any-result" v-if="!items.length">
      <strong>No results found!</strong>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import SamplesDataService from "@/services/SamplesDataService";
import {EventBus} from '@/EventBus';

export default {
  name: "List",
  components: {},
  data() {
    return {
      file: null,
      fields: ["Sha256", "AI_Verdict", "Analysis_Time", "Submitted"],
      items: [],
      newRes: {},
      lastUploadResponse: null,
      isLoading: false,
      fullPage: true,
      loading: false,
      selected: null,
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        total: 0
      },
      itemsPerPageOptions: [
        {value: 10, text: "10 submissions per page"},
        {value: 50, text: "50 submissions per page"},
        {value: 100, text: "100 submissions per page"},
        {value: 250, text: "250 submissions per page"},
      ],
      searchListResponse: [],
      searchQuery: ''
    };
  },
  methods: {
    popInfoToast() {
      //debugger; // eslint-disable-line no-debugger
      this.$bvToast.toast("Drag and drop to Upload", {
        autoHideDelay: 5000,
        noCloseButton: true,
        solid: true,
        variant: "primary",
      });
      //debugger; // eslint-disable-line no-debugger
    },
    async retrieveSamples(searchQuery) {
      this.isLoading = true
      try {

        if(searchQuery != null && searchQuery !== ''){
          this.searchListResponse = await SamplesDataService.searchList(this.pagination.currentPage, this.pagination.itemsPerPage, searchQuery);
        }else {
          this.searchListResponse = await SamplesDataService.getPaginated(this.pagination.currentPage, this.pagination.itemsPerPage);
        }

        this.items = []
        this.pagination.total = this.searchListResponse.data.total_documents;
        let results = this.searchListResponse.data.result;
        let resultSortedByDate = results.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        for (let i = 0; i < resultSortedByDate.length; i++) {
          this.lastUploadResponse = resultSortedByDate[i].created_at;
          this.items.push({
            tags: resultSortedByDate[i].tags.sort().slice(0, 10),
            moreTags: resultSortedByDate[i].tags.length - 10,
            Sha256: resultSortedByDate[i].meta.sha256,
            AI_Verdict: resultSortedByDate[i].ai_verdict,
            Analysis_Time: resultSortedByDate[i].time_taken,
            Submitted: moment(resultSortedByDate[i].created_at).fromNow(),
          });
        }

        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        this.items = [];
        this.pagination.total = 0;
      }
    },

  },
  created() {
    this.$nextTick(function () {
      this.$set(this.pagination, 'total', parseInt(sessionStorage.getItem("pagination.total") || 0))
      this.$set(this.pagination, 'itemsPerPage', parseInt(localStorage.getItem("pagination.itemsPerPage") || 10))
      this.$nextTick(function () {
        this.$set(this.pagination, 'currentPage', parseInt(sessionStorage.getItem("pagination.currentPage") || 1))
        this.retrieveSamples(this.searchQuery);
      });
    })

    EventBus.$on("searchQuery", data => {
      this.searchQuery = data;
      this.retrieveSamples(data)
    })

  },
  mounted() {
    // Code that will run only after the
    // entire view has been rendered
    if (!localStorage.getItem("pagination.itemsPerPage")) {
      this.popInfoToast();
    }
  },
  watch: {
    // whenever question changes, this function will run
    "pagination.currentPage": function (newVal) {
      sessionStorage.setItem("pagination.currentPage", newVal)
      this.retrieveSamples(this.searchQuery)
    },
    "pagination.itemsPerPage": function (newVal) {
      localStorage.setItem("pagination.itemsPerPage", newVal)
      this.retrieveSamples(this.searchQuery)
    },
    "pagination.total": function (newVal) {
      sessionStorage.setItem("pagination.total", newVal)
    }
  }
};
</script>


<style scoped>
.skeleton-table td:nth-child(1) {
  width: 60%;
}

.skeleton-table .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
}

.skeleton-table table, .skeleton-table th, .skeleton-table td {
  border: none;
}

.spinnerWrap {
  background: #00000054;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  top: 0;
  left: 0;
}

.b-toaster {
  z-index: 10000;
}

#tableData tr {

}

#tableData thead {
  /*background: #0179D4;*/
  /*background: #0179D4;*/
  color: #000000;
  font-family: Roboto, sans-serif;
  font-size: 16px;
}

.table {
  background: #fff;
}

.table th {
  font-weight: 500;
  padding: 7px 15px;
  border-top: none;
}

.table td {
  font-weight: 400;
  padding: 7px 15px;
}

.line {
  border-left: 6px solid black;
  display: inline-block;
  height: 7px;
  margin-right: 7px;
}

.line.benign {
  border-color: green;
}

.line.dangerous {
  border-color: red;
}

.line.suspicious {
  border-color: orange;
}

.not-found-any-result{
  margin: 350px auto;
  text-align: center;
}

.not-found-any-result strong{
  font-size: 24px;
}
</style>
