<template>
  <div class="container">
    <div>
      <div class="row">
        <div class="col-md-12">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {users} from "@/http-common";
import {EventBus} from '@/EventBus';

export default {
  name: "Login",
  data() {
    return {
      loginResponse: null,
      loginCallbackResponse: null,
    }
  },
  mounted() {
    let params = new URLSearchParams(window.location.search);
    let code = params.get("code");
    let state = params.get("state");

    if (code != null && state != null) {
      try {
        users.get('/oauth2_callback?code=' + code + '&state=' + state).then(response => {
          this.loginCallbackResponse = response.data
          if(this.loginCallbackResponse){
            sessionStorage.setItem("user_api_key", this.loginCallbackResponse)
            EventBus.$emit("user_api_key", this.loginCallbackResponse);
            setTimeout(function(){
              window.location = '/'
            }, 1000);
          }
        }).catch(error => {
          console.log(error)
          this.$bvToast.toast("Something went wrong", {
            autoHideDelay: 5000,
            noCloseButton: true,
            solid: true,
            variant: "danger",
          });
        })
      } catch(e) {
        this.$bvToast.toast("Something went wrong", {
          autoHideDelay: 5000,
          noCloseButton: true,
          solid: true,
          variant: "danger",
        });
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.container {
  margin-top: 3rem;
}

#login-form {
  margin-top: 5rem;
  padding: 3rem;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px 1px rgb(175 138 175);
  -moz-box-shadow: 0px 0px 5px 1px rgb(175 138 175);
  box-shadow: 0px 0px 5px 1px rgb(175 138 175);
}

input {
  background-color: #e3e3e3 !important;
  margin-left: 1rem;
}

.login-header {
  padding: 1rem;
  margin: auto;
}

.submit,
.form-check {
  margin: 1rem;
}

.register {
  color: #0069d9;
  font-weight: 700;
}

.forgot-pass {
  text-decosration: none;
  color: #878787;
  font-weight: 700;
}

.social-login {
  width: 50%;
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 10px;
}

.social-icons {
  margin-right: 1rem;
}

.facebook {
  background-color: #4166b5;
  color: #fff;
}

.google {
  background-color: #dc4c39;
  color: #fff;
}

.github {
  background-color: #444444;
  color: #fff;
}

.login-btn {
  display: block;
  width: 50%;
  margin: auto !important;
}
</style>