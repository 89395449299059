<template>
  <ul class="nav navbar-nav navbar-right">
    <li class="dropdown">
      <a href="#" @click="openLogin" id="open-click" class="dropdown-toggle text-white" data-toggle="dropdown"><b id="open-click-b">Login</b> <span class="caret"></span></a>
      <ul id="login-dp" class="dropdown-menu"  v-if="isActive">
        <li>
          <div class="row">
            <div class="col-md-12" id="dropdown_menu">
              <strong>Login via</strong>
              <div class="social-buttons">
                <a href="#" @click="authProvider('google')"  class="btn btn-google"><b-icon icon="google" font-scale="1"></b-icon> Google</a>
              </div>
              <div class="social-buttons">
                <a href="#" @click="authProvider('github')" class="btn btn-github"><b-icon icon="github" font-scale="1"></b-icon> Github</a>
              </div>
              <div class="social-buttons">
                <a href="#" @click="authProvider('microsoft')" class="btn btn-microsoft"><b-icon icon="microsoft" font-scale="1"></b-icon> Microsoft</a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import LoginDataService from "@/services/LoginDataService";

export default {
  name: "Login",
  data() {
    return {
      loginResponse: null,
      loginCallbackResponse: null,
      isActive: false
    }
  },
  mounted() {
    let self = this;
    document.addEventListener('click', function(e){
      if(e.target.id !=="dropdown_menu" && e.target.id !== "open-click-b" && e.target.id !== "open-click"){
        self.isActive = false
        if(document.getElementById("view-json-btn")){
          document.getElementById("view-json-btn").style.display = 'block';
        }

      }
    });
  },
  methods: {
    async authProvider(provider) {
      try {
        this.loginResponse = await LoginDataService.doLogin(provider);
        let result = this.loginResponse.data;
        window.location = result
      } catch(e) {
        console.log(e);
      }
    },

    openLogin(){
      this.isActive = !this.isActive;
      let showHide = this.isActive ? 'none' : 'block';
      if(document.getElementById("view-json-btn")){
        document.getElementById("view-json-btn").style.display = showHide;
      }
    }
  }
}
</script>

<style scoped>
.dropdown-toggle{
  position: relative;
}

.dropdown{
  position: relative;
}

#login-dp{
  position: absolute;
  top: 32px;
  min-width: 250px;
  padding: 14px 14px 0;
  overflow:hidden;
  display: block;
  background: #f6f7fa;
  z-index: 9999999;
  box-shadow: 1px 2px #efeded;
}

.dropdown-menu{
  right: 0 !important;
  left: unset !important;
}

#login-dp .social-buttons{
  margin:12px 0
}
#login-dp .social-buttons a{
  width: 100%;
}

.btn-google{
  color: #fff;
  background-color: #4285F4;
}
.btn-google:hover{
  color: #fff;
  background-color: #80a7e7;
}
.btn-github{
  color: #fff;
  background-color: #444444;
}
.btn-github:hover{
  color: #fff;
  background-color: #868585;
}

.btn-microsoft{
  color: #fff;
  background-color: #F25022;
}
.btn-microsoft:hover{
  color: #fff;
  background-color: #da7250;
}

@media(max-width:768px){
  #login-dp{
    background-color: inherit;
    color: #fff;
  }
  #login-dp .bottom{
    background-color: inherit;
    border-top:0 none;
  }
}
</style>