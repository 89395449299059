<template>
  <div>
    <b-skeleton-wrapper v-if="isLoading === true">
      <b-skeleton class="w-50 my-4"></b-skeleton>
      <div class="d-flex mb-4">
        <b-skeleton type="button" class="mr-2"></b-skeleton>
        <b-skeleton type="button" class="mr-2"></b-skeleton>
        <b-skeleton type="button" class="mr-2"></b-skeleton>
      </div>
      <div class="d-flex">
        <div class="flex-fill pr-5">
          <b-skeleton class="mb-3"></b-skeleton>
          <b-skeleton class="mb-3"></b-skeleton>
          <b-skeleton class="mb-3"></b-skeleton>
          <b-skeleton class="mb-3"></b-skeleton>
          <b-skeleton class="mb-3"></b-skeleton>
        </div>
        <div class="flex-fill pl-5">
          <b-skeleton class="mb-3"></b-skeleton>
          <b-skeleton class="mb-3"></b-skeleton>
          <b-skeleton class="mb-3"></b-skeleton>
          <b-skeleton class="mb-3"></b-skeleton>
          <b-skeleton class="mb-3"></b-skeleton>
        </div>
      </div>
      <b-skeleton class="mb-3"></b-skeleton>
      <div class="d-flex mb-4 mt-3">
        <b-skeleton type="button" class="mr-2"></b-skeleton>
        <b-skeleton type="button" class="mr-2"></b-skeleton>
        <b-skeleton type="button" class="mr-2"></b-skeleton>
        <b-skeleton type="button" class="mr-2"></b-skeleton>
      </div>
      <div class="skeleton-table">
      <b-skeleton-table
          :rows="10"
          :columns="2"
          :table-props="{ bordered: true, striped: true }"

      ></b-skeleton-table>
        </div>

    </b-skeleton-wrapper>
    <b-sidebar id="json-viewer" no-header right shadow backdrop backdrop-variant="light" class="w-50">
      <template #default="{ hide }">
      <div>
        <header class="d-flex p-3 position-fixed w-100 sticky-top bg-light" >
          <h3 class="flex-fill">JSON</h3>
          <b-icon icon="x" font-scale="2" @click="hide"></b-icon>
        </header>
        <div class="pt-4 pb-4 pr-4 pl-4 mt-5">
          <json-viewer :value="analysis"
          :expand-depth=5
          copyable></json-viewer>
        </div>
      </div>
      </template>
      <template #footer="{ hide }">
        <div class="px-3 py-2 flex-fill">
          <b-button size="sm" @click="hide">Close</b-button>
        </div>
      </template>
    </b-sidebar>
    <div class="d-flex justify-content-between align-items-center mb-4" v-if="isLoading === false">
      <h5 class="text-primary font-weight-bolder">
        <b-icon icon="file-earmark-code" font-scale="1" @click="$router.go(-1)"></b-icon>
        {{ $route.params.hash }} &nbsp;&nbsp;
        <b-badge class="mr-1" :variant="(analysis.ai_verdict || '').toLowerCase()">{{ analysis.ai_verdict }}</b-badge>
      </h5>
      <b-icon icon="x" font-scale="2" @click="$router.go(-1)"></b-icon>
    </div>
    <b-button size="sm" variant="primary" id="view-json-btn" v-b-toggle.json-viewer v-if="isLoading === false">JSON View</b-button>
    <b-tabs content-class="mt-3" v-if="analysis.meta">
      <b-tab title="Overview">
        <div class="d-flex">
          <dl class="flex-fill mb-0">
            <dt>Filename:</dt>
            <dd>{{ analysis.meta.file_name }}</dd>
            <dt>SSDeep:</dt>
            <dd>{{ analysis.meta.ssdeep }}</dd>
            <dt>MD5:</dt>
            <dd>{{ analysis.meta.md5 }}</dd>
            <dt>SHA256:</dt>
            <dd>{{ analysis.meta.sha256 }}</dd>
          </dl>

          <dl class="flex-fill mb-0">
            <dt>Operating System:</dt>
            <dd>
              {{
                ((analysis.file_capabilities || {}).properties || {}).os ||
                "N/A"
              }}
            </dd>
            <dt>Architecture:</dt>
            <dd>
              {{
                ((analysis.file_capabilities || {}).properties || {})
                    .arch || "N/A"
              }}
            </dd>
            <dt>File Size:</dt>
            <dd>{{ analysis.meta.file_size }}</dd>
            <dt>File Type:</dt>
            <dd>{{ analysis.meta.file_type }}</dd>
          </dl>
        </div>
        <dl>
          <dt>Tags:</dt>
            <dd><b-badge class="mr-1" variant="secondary" v-for="(item, i) in analysis.tags" :key="'tags' + i">
              {{ item }}
            </b-badge></dd>
        </dl>
        <hr/>
        <b-tabs content-class="mt-3">
          <b-tab
              title="Capabilities"
              :disabled="
              !(analysis.file_capabilities || {}).capability_namespaces || Object.keys((analysis.file_capabilities || {}).capability_namespaces || {}).length == 0
            "
          >
            <table class="table table-striped blue-stripe" v-if="analysis.file_capabilities">
              <tbody>
              <tr v-for="(value, propertyName, index) in analysis.file_capabilities.capability_namespaces"
                  :key="propertyName + index">
                <td>{{ propertyName }}</td>
                <td>{{ value }}</td>
              </tr>
              </tbody>
            </table>
          </b-tab>
          <b-tab
              title="Mitre"
              :disabled="!(analysis.file_capabilities || {}).attacks || Object.keys((analysis.file_capabilities || {}).attacks || {}).length == 0"
          >
            <table class="table table-striped blue-stripe" v-if="analysis.file_capabilities">
              <tbody>
              <tr v-for="(value, propertyName, index) in analysis.file_capabilities.attacks"
                  :key="propertyName + index">
                <td>{{ propertyName }}</td>
                <td><div v-for="(item, innerIndex) in value" :key="item + innerIndex">{{item}}</div></td>
              </tr>
              </tbody>
            </table>
          </b-tab>
          <b-tab
              title="MBC"
              :disabled="!(analysis.file_capabilities || {}).mbc || Object.keys((analysis.file_capabilities || {}).mbc || {}).length == 0">
           <table class="table table-striped blue-stripe" v-if="analysis.file_capabilities">
              <tbody>
              <tr v-for="(value, propertyName, index) in analysis.file_capabilities.mbc"
                  :key="propertyName + index">
                <td>{{ propertyName }}</td>
                <td><div v-for="(item, innerIndex) in value" :key="item + innerIndex">{{item}}</div></td>
              </tr>
              </tbody>
            </table>
          </b-tab>
          <b-tab title="Artifacts" :disabled="!analysis.artifacts">
            <b-tabs vertical v-if="analysis.artifacts" nav-wrapper-class="w-25">
              <b-tab
                  v-for="artifactTab in artifactTabs"
                  :key="artifactTab.key"
                  :disabled="!analysis.artifacts[artifactTab.key]">
                <template #title>
                  <div class="d-flex justify-content-between align-items-center">
                    <span>{{ artifactTab.name }}</span>
                    <span class="badge badge-primary badge-pill" v-if="analysis.artifacts[artifactTab.key]">{{
                        analysis.artifacts[artifactTab.key].length
                      }}</span>
                  </div>
                </template>
                <b-card-text>
                  <ul>
                    <li v-for="item in analysis.artifacts[artifactTab.key]" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-tab>
        </b-tabs>
      </b-tab>

      <b-tab title="Yara Signatures">
          <div class="d-flex">
            <b-row>
              <b-col cols="12" class="mt-3 mb-3 border-bottom" v-for="signature in analysis.yara_signatures_detected" :key="signature.signature">
                <div class="flex-fill">
                  <dt class="d-inline-block">Signature:</dt> &nbsp;
                  <dd class="d-inline-block">{{ signature.signature }}</dd> &nbsp;
                </div>
                <div class="flex-fill">
                  <dt class="d-inline-block">Description:</dt> &nbsp;
                  <dd class="d-inline-block">{{ signature.description || "N/A" }}</dd> &nbsp;
                </div>
                <div class="flex-fill">
                  <dt class="d-inline-block">Mitre:</dt> &nbsp;
                  <dd class="d-inline-block">{{ signature.mitre || "N/A" }}</dd> &nbsp;
                </div>
                <div class="flex-fill">
                  <dt class="d-inline-block">Reference:</dt> &nbsp;
                  <dd class="d-inline-block">{{ signature.reference || "N/A" }}</dd> &nbsp;
                </div>
                <div class="flex-fill">
                  <dt>Matches:</dt>
                  <b-table small hover :items="signature.matches" id="tableData">
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        <hr>
      </b-tab>

      <b-tab title="Strings">
        <b-tabs content-class="mt-3">
          <b-tab title="Decoded"
                 :disabled="!analysis.strings.decoded_strings || !analysis.strings.decoded_strings.length">
            <b-badge class="" variant="info" v-for="(item, i) in analysis.strings.decoded_strings" :key="'key' + i">
              {{ item }}
            </b-badge>
          </b-tab>
          <b-tab title="Stack" :disabled="!analysis.strings.stack_strings || !analysis.strings.stack_strings.length">
            <b-badge class="" variant="info" v-for="(item, i) in analysis.strings.stack_strings" :key="'key' + i">
              {{ item }}
            </b-badge>
          </b-tab>
          <b-tab title="Static" :disabled="!analysis.strings.static_strings || !analysis.strings.static_strings.length">
            <b-badge class="" variant="info" v-for="(item, i) in analysis.strings.static_strings" :key="'key' + i">
              {{ item }}
            </b-badge>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>
  </div>
</template>


<script>
import SamplesDataService from "@/services/SamplesDataService";
import JsonViewer from 'vue-json-viewer'

export default {
  name: "Detail",
  components: {
    JsonViewer
  },
  data() {
    return {
      analysis: {},
      isLoading: false
    };
  },
  methods: {
    async retrieveSampleDetails(sha256) {
      this.isLoading = true;
      try {
        console.log(sha256);
        let response = await SamplesDataService.getOne(sha256);
        this.analysis = response.data;
        console.log(this.analysis);
        this.isLoading = false
      } catch (e) {
        console.log(e);
        this.isLoading = false
      }
    },
  },
  computed: {
    artifactTabs() {
      //debugger // eslint-disable-line no-debugger
      if (this.analysis && this.analysis.artifacts) {
        return [
          {"name": "Domains", key: "domains"},
          {"name": "Emails", key: "emails"},
          {"name": "IP Addresses", key: "ip_address"},
          {"name": "Cryptocurrencies", key: "crypto"},
          {"name": "Registry Keys", key: "registry_keys"},
          {"name": "SQL Statements", key: "sql"},
          {"name": "File Paths", key: "file_paths"},
        ]
      }
      return []
    }
  },
  created() {
    this.retrieveSampleDetails(this.$route.params.hash);
  },
};
</script>

<style>
svg.bi-x.b-icon.bi:hover {
  background-color: #ba141a;
  color: #FFFFFF;
  cursor: pointer;
}

.badge-info {
  margin-right: 5px
}

.badge-benign {
  background: green;
  color: #FFFFFF;
}

.badge-dangerous {
  background: red;
  color: #FFFFFF;
}

.badge-suspicious {
  background: orange;
  color: #FFFFFF;
}

.table-striped.blue-stripe tbody tr:nth-of-type(odd) {
    background-color: rgb(214 233 255 / 52%);
}

#view-json-btn {
    position: absolute;
    right: 50px;
    top: 136px;
    z-index: 1;
}

#json-viewer {
  width: 70vw;
}

.border-bottom{
  border-bottom: 1px solid darkcyan !important;
}
</style>
