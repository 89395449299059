<template>
  <div>
    <div class="elapsed d-flex align-items-center justify-content-center" v-if="show">
      <div class="elapsed-inner d-flex align-content-center justify-content-center center">
        <h3 class="d-flex justify-content-center align-items-center flex-column"> <b-icon icon="arrow-clockwise" animation="spin" font-scale="2"></b-icon> {{this.secondsElapsed}} seconds</h3>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TimeElapsed',
  props: {
    show: {type: Boolean}
  },
  data: function() {
    return {
      secondsElapsed: 0,
      interval: null
    }
  },
  created() {
    if (this.show) {
      this.startTimer()
    }
  },

  methods: {
    startTimer() {
      this.interval = setInterval(() => {
        this.secondsElapsed += 1;
      }, 1000)
    }
  },
  computed: {
  },
  watch: {
    show (newValue) {
      if (newValue) {
        this.secondsElapsed = 0;
        this.startTimer();
      }
      else {
        if (this.interval) {
          clearInterval(this.interval)
          this.secondsElapsed = 0;
        }
      }
    }
  }
}
</script>

<style scoped>
  .elapsed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(2px);
    background: #fffefec4;
    padding: 50px
  }

  .dropzone-inner {
    border: 5px dashed #0179d4;
    height: 96%;
    width: 96%;
  }
</style>
