import Vue from 'vue';
import VueRouter from 'vue-router';

import List from '@/views/List.vue';
import Detail from '@/views/Detail.vue';
import Login from '@/views/Auth/Login.vue';
import NotFound from '@/views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/404',
    name: "NotFound",
    component: NotFound
  },
  { path: '*',
    redirect: '/404'
  },
  {
    path: '/',
    name: "samples",
    component: List,
  },
  {
    path: '/detail/:hash',
    component: Detail,
    name: "detail"
  },
  {
    path: '/login',
    component: Login,
    name: "login"
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
});


export default router;
