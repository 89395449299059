<template>
  <header class="header">
    <div class="wrapper">

      <div class="headFlex">
          <div>
            <h1 style="cursor:pointer" @click="backToHome">Analyze.rs</h1>
          </div>
          <div>
            <div class="searchWrap">
              <input type="text" v-model="searchQuery" class="form-control" @input="updateInput"  placeholder="Search for md5/sha256/tags">
              <b-icon icon="search" font-scale="1"></b-icon>
              <b-icon v-if="searchQuery"  icon="x-circle-fill" font-scale="1" class="b-close-icon" @click="clearText"></b-icon>
            </div>
          </div>
          <div>
            <LogOut v-if="user_api_key !== '' && user_api_key !== null"  :api_key="user_api_key" />
            <Login v-else />
<!--            <button v-if="currentRouteName != 'login'" type="button" class="btn btn-light" @click="redirectToLogin">Login</button>-->
          </div>
      </div>
    </div>
  </header>
</template>

<script>
import {EventBus} from '@/EventBus';
import Login from '@/views/Login'
import LogOut from "@/views/LogOut";

export default {
  name: 'Header',
  components: {
    LogOut,
    Login
  },
  data() {
    return {
      searchQuery: '',
        user_api_key: ''
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    backToHome(){
      window.location.href = '/'
    },

    updateInput () {
      if(this.currentRouteName !== 'samples') {
        this.$router.push({name:'samples'})
      }

      EventBus.$emit("searchQuery", this.searchQuery);
    },

    clearText(){
      this.searchQuery = ''
      EventBus.$emit("searchQuery", this.searchQuery);
    },

    redirectToLogin(){
      this.$router.push({name:'login'})
    },

  },

  created() {
    EventBus.$on("user_api_key", data => {
      this.user_api_key = data
    })
    this.user_api_key = sessionStorage.getItem("user_api_key")
  }
}
</script>

<style scoped>
.header {
  background: #0179D4;
  margin-bottom: 30px;
  position: fixed;
  width: 100%;
  top:0;
  z-index: 999999;
}

.headFlex {
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headFlex h1 {
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  margin-left: 20px;
}

.headFlex .dropdown .btn {
  padding: 0;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.menuBtn {
  padding: 0;
  border: none;
  background: transparent;
}

.headFlex>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profileDrop img.dp {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.profileDrop img:nth-of-type(2) {
  margin-left: 5px;
}

.profileDrop {
  margin-left: 25px;
}

.notifyDrop .btn {}

.notifyDrop /deep/ .btn:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background: #F44336;
  border-radius: 50%;
  border: 2px solid #0179D4;
}

.headFlex .dropdown /deep/ .btn {
  padding: 0;
  background: transparent;
  position: relative;
}

.btnWrap .btn {
  border: none;
  background: #fff;
  width: 100px;
  font-size: 14px;
  color: #000000a6;
  padding: 5px 5px;
  border-radius: 5px;
  margin-right: 10px;
}

.btnWrap .btn img {
  margin-right: 5px;
}

.searchWrap {
  /* margin-right: 15px; */
  position: relative;
  /* flex: 1 1 auto; */
  min-width: 600px;
}

.searchWrap img, .b-icon {
  position: absolute;
  top: 50%;
  left: 17px;
  height: 16px;
  transform: translate(0, -50%);
}

.searchWrap .form-control {
  font-size: 14px;
  padding: 5px 5px 5px 40px;
  background: #fff;
  /*border-radius: 9px;*/
  border: none;
  height: 25px;
}

.b-close-icon{
  cursor: pointer;
  right: 8px !important;
  left: unset;
}
</style>
