<template>
  <div id="app" @dragenter.prevent="showFileUpload=true" @dragleave.prevent="showFileUpload=false">
    <VueFullScreenFileDrop @drop='onDrop'>
    </VueFullScreenFileDrop>
    <time-elapsed :show="uploadInProgress"></time-elapsed>
    <NavBar />
    <div class="main p-5">
      <router-view/>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import VueFullScreenFileDrop from 'vue-full-screen-file-drop';
import SamplesDataService from "@/services/SamplesDataService";
import TimeElapsed from "@/components/TimeElapsed";

export default {
  components: {
    TimeElapsed,
    NavBar,
    Footer,
    VueFullScreenFileDrop
  },
  data (){
    return {
      showFileUpload: false,
      uploadInProgress: false,
    }
  },
  methods: {
    async onDrop(formData, files){
      console.log(formData, files)
      try {
        this.uploadInProgress = true;
        let response = await SamplesDataService.uploadFile(formData);
        this.uploadInProgress = false;
        this.$router.push({"name": "detail", params:{"hash": response.data.meta.sha256}})
      } catch (e) {
        this.uploadInProgress = false;
        this.$bvToast.toast(e.response.data.message, {
          autoHideDelay: 5000,
          title: "Error",
          noCloseButton: true,
          solid: true,
          variant: "danger",
        });
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://pro.fontawesome.com/releases/v5.10.0/css/fontawesome.css');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.main {
  margin-top: 30px;
  padding-bottom: 50px;
}

/*** Generic Globals**/
::-webkit-scrollbar{width:2px;height:2px;}
::-webkit-scrollbar-track{box-shadow:inset 0 0 5px transparent;border-radius:10px;}
::-webkit-scrollbar-thumb{background:transparent;border-radius:10px;}
::-webkit-scrollbar-thumb:hover{background:transparent;}
*:focus{outline:-webkit-focus-ring-color none;outline:none;}
a:visited,a:focus,a:active,a:hover{cursor:pointer;outline:none!important;text-decoration:none;}
.btn:focus,button:focus{outline:none;box-shadow:none;}
.btn:hover,button:hover{cursor:pointer;}

/*=====*/
::-webkit-input-placeholder{opacity:1;}
:-moz-placeholder{opacity:1;}
::-moz-placeholder{opacity:1;}
:-ms-input-placeholder{opacity:1;}

/*=====*/
input:focus::-webkit-input-placeholder,textarea:focus::-webkit-input-placeholder{opacity:0.5;}
input:focus:-moz-placeholder,textarea:focus:-moz-placeholder{opacity:0.5;}
input:focus::-moz-placeholder,textarea:focus::-moz-placeholder{opacity:0.5;}
::selection{background:#FFFF00;color:#000;text-shadow:none;}
::-moz-selection{background:#FFFF00;color:#000;text-shadow:none;}
input:focus,select:focus{outline:none;}
input:-webkit-autofill,textarea:-webkit-autofill,select:-webkit-autofill,input:-webkit-autofill:focus,textarea:-webkit-autofill:focus,select:-webkit-autofill:focus,input:-webkit-autofill:hover,textarea:-webkit-autofill:hover,select:-webkit-autofill:hover{-webkit-box-shadow:0 0 0px 1000px white inset;box-shadow:0 0 0px 1000px white inset;background-color:#fff!important;}

/*========*/
img{max-width:100%;height:auto;}
a,a *,a:before,a:after,.btn,.btn *{-webkit-transition:.3s linear;-moz-transition:.3s linear;-ms-transition:.3s linear;-o-transition:.3s linear;transition:.3s linear;}
a{text-decoration:none;outline:none;color:#0179D4; font-weight: 700; display:inline-block;}
a:focus,a:hover{text-decoration:none;color:#1C1C1C;}

/*======Changeable Global=========*/
body{color:#989898;font-size:15px;font-family: 'Roboto', sans-serif;font-weight:400;background-color:#FFFFFF;}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6{line-height:1;color:#353535;font-weight:400;margin:0;font-family: 'Roboto', sans-serif;}
footer {
  display:flex;
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  width: 100%;
  border-top: 1px solid white;
  justify-content:center;
  align-items:center;
  z-index:5;

  -webkit-box-shadow: 0px 3px 85px 0px rgba(0,0,0,0.10);
-moz-box-shadow: 0px 3px 85px 0px rgba(0,0,0,0.10);
box-shadow: 0px 3px 85px 0px rgba(0,0,0,0.10);
}

/*=============*/
h1 small,.h1 small,h2 small,.h2 small,h3 small,.h3 small,h4 small,.h4 small,h5 small,.h5 small,h6 small,.h6 small{display:block;color:#7b6856;font-weight:300;font-size:66%;line-height:160%;font-family: 'Roboto', sans-serif;font-style:normal;margin-top:35px;padding:0 100px;}
p{margin:0 0 20px;font-size:16px;line-height:25px;}
b{font-weight:700;}

/* Buttons */
.form-control:focus{box-shadow: none;}

</style>