import axios from "axios";

const domain = "/api";

const headers = {
    'Content-type': 'application/json',
    'x-api-key': 'aabe3ac8db441aceb08cc3ab6c345e1f',
};

const samples = axios.create({
    baseURL: domain + "/file/scan/static",
    withCredentials: true,
    headers: headers
});

const users = axios.create({
    baseURL: domain + "/users",
    withCredentials: true,
    headers: headers
});

axios.defaults.headers

export {
    samples,
    users
}
